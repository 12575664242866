import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ZoomLineChart from "../components/zoomlinechart"
import ZoomLineChart2 from "../components/zoomlinechart2"
import ZoomLineChart3 from "../components/charttest1"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <ZoomLineChart />
    <ZoomLineChart2 />
    <ZoomLineChart3 />
  </Layout>
)

export default IndexPage
